import { Configuration } from '@azure/msal-browser';

export const msalConfig: Configuration = {
  auth: {
    clientId: "fc249509-2777-4c22-8969-61149f66b89d",
    authority: "https://login.microsoftonline.com/c9870c14-4129-4d46-aad2-3f691939642c",
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    allowNativeBroker: false, // Disables WAM Broker
  }
};


// Scopes for the login request
export const loginRequest = {
  scopes: ["api://tenants-backend-y9ol3J/.default"] // Example scope for Microsoft Graph, adjust based on your needs
};