import React, { useEffect, useMemo, useState } from 'react';
import Text from "@embracesbs/component-text";
import { Audit, Tenant } from '../../Interfaces/Types';
import Table from "@embracesbs/component-table";
import TableRow from "@embracesbs/component-tablerow";
import { FeatureFlags } from '../../Interfaces/Types';
import callApiWithToken from '../../Utilities/ApiService/ApiService';
import DatePicker from "@embracesbs/component-datepicker";
import Container from '@embracesbs/component-container/Container';
import TextField from '@embracesbs/component-textfield/TextField';
import TextFieldWrapper from '@embracesbs/component-textfieldwrapper/TextFieldWrapper';
import JSONInput from 'react-json-editor-ajrm';









interface tenantDetailsProbs {
    tenant?: Tenant
}

const style = {
  maxHeight: '50%', // Ensures that the div doesn't grow beyond the outer div's height
  overflow: 'auto' // Enables scrolling
};




const Maintenance: React.FC<tenantDetailsProbs> = ({tenant}) => {
    const [featureFlags,setFeatureFlags] = React.useState<Audit[]>([]);
    const [featureFlagsLoaded,setFeatureFlagsLoaded] = React.useState<Boolean>(false);
    const [value, setValue] = useState<{
      from: Date;
      to: Date;
    } | undefined>({
      from: new Date(),
      to: new Date()
    });
    
    const sortByLabel = (a: FeatureFlags, b: FeatureFlags) => {
        return a.label.localeCompare(b.label);
      };


    useEffect(() => {
        callApiWithToken({
            endpoint: `/api/audit/${tenant?.id}`,
            method: 'GET', // Your request payload
          }).then(data => {
            setFeatureFlags(data);
            setFeatureFlagsLoaded(true);
          }).catch(error => {
            console.error(error);
          });
        
      }, [tenant]);


   if(!tenant){
    return(
        <>     
       <Text align='center' textColor='grey'>Please select a tenant</Text>
        </>
    )
   }   
      

  return    (
    <div style={style}>


      <Container>
      <TextFieldWrapper labelText={"Date range"}>
      <TextField placeholder="Select date range" value={`${value?.from.toDateString()}-${value?.to.toDateString()}`} />
</TextFieldWrapper>
      <DatePicker isRange selectedDayFrom={value?.from} selectedDayTo={value?.to} onSelectingDays={({
      selectedDayFrom,
      selectedDayTo
    }) => {
      const startOfFromDay = new Date(selectedDayFrom);
      startOfFromDay.setHours(0, 0, 0);
      const endOfToDay = new Date(selectedDayTo);
      endOfToDay.setHours(23, 59, 59);
      setValue({
        from: startOfFromDay,
        to: endOfToDay
      });
    }} isRightButtonDisabled={!value} />


</Container>


<Container>

    <TextFieldWrapper labelText={"StartTime"}>
            <TextField

                value="12:00"

                type="text"
                variant="default"
            />
            </TextFieldWrapper>
            <TextFieldWrapper labelText={"EndTime"}>
            <TextField

                value={'12:00'}

                type="text"
                variant="default"
            />
            </TextFieldWrapper>

 
</Container>
<Container></Container>





</div>
  );
};

export default Maintenance;