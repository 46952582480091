import Container from '@embracesbs/component-container/Container';
import Header from '@embracesbs/component-header/Header';
import HeaderChild from '@embracesbs/component-headerchild/HeaderChild';
import React, { useEffect, useMemo, useState } from 'react';
import Text from "@embracesbs/component-text";
import TabGroup from '@embracesbs/component-tabgroup/TabGroup';
import { Tenant } from '../../Interfaces/Types';
import TernantDeploymentSettings from './TernantDeploymentSettings';
import Loader from '../Loader/Loader';
import TenantMonitoring from './TenantMonitoring';
import Switch from "@embracesbs/component-switch";
import Table from "@embracesbs/component-table";
import TableRow from "@embracesbs/component-tablerow";
import { FeatureFlags } from '../../Interfaces/Types';
import callApiWithToken from '../../Utilities/ApiService/ApiService';





interface tenantDetailsProbs {
    tenant?: Tenant
}


const FeatureFlag: React.FC<tenantDetailsProbs> = ({tenant}) => {
    const [featureFlags,setFeatureFlags] = React.useState<FeatureFlags[]>([]);
    const [featureFlagsLoaded,setFeatureFlagsLoaded] = React.useState<Boolean>(false);
    
    const sortByLabel = (a: FeatureFlags, b: FeatureFlags) => {
        return a.label.localeCompare(b.label);
      };

      const toggleFeatureFlag = (name: string) => {
        setFeatureFlagsLoaded(true);
        const updatedFlags = featureFlags.map(flag => {
            if (flag.name === name) {
              return { ...flag, enabled: !flag.enabled };
            }
            return flag;
          });


          callApiWithToken({
            endpoint: `/api/appconfig/${tenant?.id}/featureflags`,
            method: 'PUT',
            body: updatedFlags
          }).then(data => {
            setFeatureFlags(data.sort(sortByLabel));
            setFeatureFlagsLoaded(true);
            console.log(featureFlags)
          }).catch(error => {
            console.error(error);
          });

    };


    useEffect(() => {
        callApiWithToken({
            endpoint: `/api/appconfig/${tenant?.id}/featureflags`,
            method: 'GET', // Your request payload
          }).then(data => {
            setFeatureFlags(data.sort(sortByLabel));
            setFeatureFlagsLoaded(true);
          }).catch(error => {
            console.error(error);
          });
        
      }, [tenant]);


   if(!tenant){
    return(
        <>     
       <Text align='center' textColor='grey'>Please select a tenant</Text>
        </>
    )
   }   
      

  return    (
    <>

<Table
  >
{featureFlags.map((item) => (
 <TableRow>
 <Switch name={item.name} label={item.name} value='a' isChecked={item.enabled}  onClick={() =>{ toggleFeatureFlag(item.name); console.log(featureFlags)}}  />
 <Text>{item.label}</Text>
</TableRow>  

           ))}


    </Table>


    </>
  );
};

export default FeatureFlag;