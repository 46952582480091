
import logo from './logo.svg';
import './App.css';
import Layout from "@embracesbs/component-layout";
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import TenantPage from './Pages/TenantPage';
import MainMenu from './Components/MainMenu/MainMenu';
import AuthenticatedRoute from './Components/Auth/AuthenticatedRoute';
import useServiceWorker from './/Components/ServiceWorker/useServiceWorker';
import { useIsAuthenticated } from '@azure/msal-react';







function App() {

  useServiceWorker();
  const isAuthenticated = useIsAuthenticated();

  return (
    <>


      <Layout


        columns="60px 1fr"

      >
        <aside style={{ height: "100%" }}>
          <MainMenu />
        </aside>
        <main style={{ height: "100%", background: "#F2F2F4" }}>
          <Router>
            <Routes> 

        
                            <Route path='/' element={<AuthenticatedRoute component={TenantPage} />} />
                            <Route path="/subscriptions" element={<AuthenticatedRoute component={TenantPage} />} />
                            <Route path="/subscriptions/:subscriptionid" element={<AuthenticatedRoute component={TenantPage} />} />
                            <Route path="/subscriptions/:subscriptionid/tenant/:tenantid" element={<AuthenticatedRoute component={TenantPage} />} />
              
 

              


            </Routes>
          </Router>
        </main>
      </Layout>

    </>
  );
}

export default App;
