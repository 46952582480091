import React, { useState } from 'react';
import Text from "@embracesbs/component-text";


interface LoaderPropInterface {
    viewbox: string;
    ringCollor: string;
    text?: string;
    showLoader?: boolean;
}


const Loader: React.FC<LoaderPropInterface> = ({viewbox, ringCollor, text, showLoader}) => {
    
   
      //-50 10 200 50

  return    (
    <>
    <Text align='center' textColor='lightgrey'>{text}</Text>
    {
      showLoader && 
<svg version="1.1" id="L4" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
      viewBox={viewbox} enable-background="new 0 0 0 0" >
      <circle fill={ringCollor} stroke="none" cx="6" cy="50" r="6">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite"
          begin="0.1"/>    
      </circle>
      <circle fill={ringCollor} stroke="none" cx="26" cy="50" r="6">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite" 
          begin="0.2"/>       
      </circle>
      <circle fill={ringCollor} stroke="none" cx="46" cy="50" r="6">
        <animate
          attributeName="opacity"
          dur="1s"
          values="0;1;0"
          repeatCount="indefinite" 
          begin="0.3"/>     
      </circle>
    </svg>
    }
   
    </>
  );
};

export default Loader;