import React, { useState, useEffect } from 'react';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';

const AuthenticatedRoute: React.FC<{ component: React.ElementType }> = ({ component: Component }) => {
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [isInitialized, setIsInitialized] = useState(false);

    useEffect(() => {
        if (inProgress === InteractionStatus.None) {
            setIsInitialized(true);
        }
    }, [inProgress]);

    useEffect(() => {
        if (isInitialized && !isAuthenticated) {
            instance.loginRedirect().catch(e => console.error(e));
        }
    }, [isInitialized, isAuthenticated, instance]);

    if (!isInitialized) {
        return <div>Loading...</div>;
    }
    return <Component />;
};

export default AuthenticatedRoute;