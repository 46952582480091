import React, { useState } from 'react';
import { Tenant } from '../../Interfaces/Types';
import Button2 from '@embracesbs/component-button2/Button2';
import Icon from '@embracesbs/component-icon/Icon';
import callApiWithToken from '../../Utilities/ApiService/ApiService';




interface SupportLoginDetailsProbs {
    tenant?: Tenant
}

//Those full urls should come from backend and not hardcoded
const SupportLogin: React.FC<SupportLoginDetailsProbs> = ({tenant}) => {

    const [Isloading,SetIsLoading] = useState(false);
    const [buttonText,setButtonText] = useState("Support Login");
    
    const handleLogin =() =>
    {
        SetIsLoading(true);
        setButtonText("Building support login ...")
        let supportUrl = "";
        callApiWithToken({
            endpoint: `/api/tenant/${tenant?.id}/tenantsconfiguration/supporturl`,
            method: 'GET', 
          }).then(data => {
                if(tenant?.environment == "production")
                {
                    supportUrl = `https://auth.embracecloud.nl/auth/realms/${tenant.tenantName}/protocol/openid-connect/auth?client_id=embracecloud&redirect_uri=${data}&response_type=code&scope=openid+suite-user+identity-provider-user&kc_idp_hint=support`;
                }else if(tenant?.environment == "beta"){
                    supportUrl = `https://auth.beta.embracecloud.nl/auth/realms/${tenant.tenantName}/protocol/openid-connect/auth?client_id=embracecloud&redirect_uri=${data}&response_type=code&scope=openid+suite-user+identity-provider-user&kc_idp_hint=support`;
                }else{
                    supportUrl = `https://auth.${tenant?.environment}.embracecloud.io/auth/realms/${tenant?.tenantName}/protocol/openid-connect/auth?client_id=embracecloud&redirect_uri=${data}&response_type=code&scope=openid+suite-user+identity-provider-user&kc_idp_hint=support`
                }
                setButtonText("Support Login")
                window.open(supportUrl, "_blank", "noopener noreferrer")

          }).catch(error => {

            console.error(error);
          });
    }

   if(!tenant){
    return(
        <>
         
        </>
    )
   }   
      

  return    (
    <>
    <div style={{ position: 'absolute', bottom: 28, width: '100%' }}>
                <Button2
        buttonRadius="8px"
        buttonSize="40px"
        leftElement={<Icon iconName="Login2" size={16}/>}
        onClick={handleLogin}
        text={buttonText}
        

        variant="contained"
        />
</div>
    </>
  );
};

export default SupportLogin;