import Container from '@embracesbs/component-container/Container';
import Header from '@embracesbs/component-header/Header';
import HeaderChild from '@embracesbs/component-headerchild/HeaderChild';
import React, { useEffect, useMemo, useState } from 'react';
import Text from "@embracesbs/component-text";
import TabGroup from '@embracesbs/component-tabgroup/TabGroup';
import { Tenant } from '../../Interfaces/Types';
import TernantDeploymentSettings from './TernantDeploymentSettings';
import Loader from '../Loader/Loader';
import TenantMonitoring from './TenantMonitoring';
import FeatureFlag from './FeatureFlag';
import AuditLog from './AuditLog';
import Maintenance from './Maintenance';
import Button2 from '@embracesbs/component-button2/Button2';
import Icon from '@embracesbs/component-icon/Icon';




interface tenantDetailsProbs {
    tenant?: Tenant
    onUpdate: () => void;
}


const TenantDetails: React.FC<tenantDetailsProbs> = ({tenant, onUpdate}) => {

    const [selectedTab,setSelectedTab] = useState(1);

    


    const handleClick =(id: number) =>
    {
       setSelectedTab(id);
    }

    const renderDetailsContent = useMemo(() => {

        switch(selectedTab){
          case 1: {
            return <TernantDeploymentSettings onUpdate={() => onUpdate} tenant={tenant} />;
          }
          case 2: {
            return <FeatureFlag tenant={tenant}></FeatureFlag>
          }
          case 3: {
            return <Maintenance tenant={tenant}></Maintenance>
          }
          case 5: {
          
            return (  
              <>
              <TenantMonitoring tenant={tenant}></TenantMonitoring>
         
              </>
            )
          }
          case 6: 
          {
            return <AuditLog tenant={tenant}></AuditLog>
          }
        }

        return <div>Not yet implemented</div>
      }, [selectedTab, tenant]);





    const tabs = [
        {
          id: 1,
          text: "Deploytime Setting",
        },
        {
          id: 2,
          text: "Feature Flags",
        },
        {
          id: 3,
          text: "Maintenance",
        },
        {
            id: 4,
            text: "Notifications",
        },
        {
          id: 5,
          text: "Monitoring",
        },
        
        {
          id: 6,
          text: "Audit Log",
        },
      ];

   if(!tenant){
    return(
        <>     
       <Text align='center' textColor='grey'>Please select a tenant</Text>
        </>
    )
   } 

   console.log(tenant.deployStatus)
   
   if(tenant.deployStatus == "initializing"){
    return (<><Text align='center' textColor='grey'>Tenant {tenant.tenantName} is initializing to view the progress press PipelineUrl link </Text> 
    </> )
   
   }
      

  return    (
    <>




        <Text textStyle='h6'>{tenant.tenantDisplayname}</Text>
 

        <br></br>
        <TabGroup onTabClick={handleClick} tabs={tabs} activeKey={1} />
        <br></br>
        <br></br>
        {renderDetailsContent}
    </>
  );
};

export default TenantDetails;