import React, { useState } from 'react';
import Text from "@embracesbs/component-text";
import { Tenant } from '../../Interfaces/Types';
import Button2 from '@embracesbs/component-button2/Button2';
import Icon from '@embracesbs/component-icon/Icon';




interface SupportLoginDetailsProbs {
    tenant?: Tenant
}


const TenantMonitoring: React.FC<SupportLoginDetailsProbs> = ({tenant}) => {
    
    const handleLogin =() =>
    {
       
        window.open(`https://embracecloud-g7cshkg8egawckc5.weu.grafana.azure.com/d/af87acdb-4b69-4fe8-ac81-c344c0b4b03e/embrace-suite?orgId=1&var-tenant=${tenant?.tenantName}`, "_blank", "noopener noreferrer")    }

   if(!tenant){
    return(
        <>
         
        </>
    )
   }   
      

  return    (
    <>
     <Text textColor='lightgrey' align='center'>Please keep in mind that the tenant specific graphs are only filled when there was usage and only works for environments beta and up</Text>  
     <br></br>
                <Button2
        buttonRadius="8px"
        buttonSize="40px"
        leftElement={<Icon iconName="LayoutDashboard" size={16}/>}
        onClick={handleLogin}
        text={`Grafana dashboard ${tenant.tenantName}`}
        

        variant="contained"
        />

    </>
  );
};

export default TenantMonitoring;