import Container from '@embracesbs/component-container/Container';
import React, { useEffect, useState } from 'react';
import Text from "@embracesbs/component-text";
import { Tenant, TenantConfig } from '../../Interfaces/Types';
import Divider from '@embracesbs/component-divider/Divider';
import Switch from "@embracesbs/component-switch";
import TextField from "@embracesbs/component-textfield";
import TextFieldWrapper from '@embracesbs/component-textfieldwrapper/TextFieldWrapper';
import callApiWithToken from '../../Utilities/ApiService/ApiService';
import Loader from '../Loader/Loader';
import Button2 from '@embracesbs/component-button2/Button2';
import Autocomplete from '@embracesbs/component-autocomplete/Autocomplete';



const style = {
    maxHeight: '80%', // Ensures that the div doesn't grow beyond the outer div's height
    overflow: 'auto' // Enables scrolling
  };


interface TernantDeploymentSettingsProbs {
    tenant?: Tenant
    onUpdate: (tenant: any) => void;
}


const TernantDeploymentSettings: React.FC<TernantDeploymentSettingsProbs> = ({tenant, onUpdate}) => {
    const [tenantConfig,setTenantConfig] = useState<TenantConfig | undefined>(undefined);
    const [tenantConfigIsLoading, setTenantConfigIsLoading] = useState(true);

    useEffect(() => {
        setTenantConfigIsLoading(true)
        callApiWithToken({
            endpoint: `/api/tenant/${tenant?.id}/tenantsconfiguration`,
            method: 'GET', // Your request payload
          }).then(data => {
            setTenantConfig(data);
            setTenantConfigIsLoading(false);
          }).catch(error => {
            console.error(error);
          });
        
      }, [tenant]);


      const checkUpdate =(state?: string) => 
      {
          if(state === "initialized" || state === "finished" || state === "finished")
          {
            return false;
          }

          return true;
      }


      const handleTenantConfigurationChange = (name: string, value: any) => {

        setTenantConfig(prevConfig => {
          // Return early if prevConfig is undefined for some reason
          if (!prevConfig) return prevConfig;
      
          // Here we check if `name` is a key of TenantConfig to satisfy TypeScript
          if (name in prevConfig) {
            return {
              ...prevConfig,
              [name]: value, // Assuming you want to store "true"/"false" strings
            } as TenantConfig; // Explicitly cast the return type as TenantConfig
          }
          return prevConfig;
        });

        console.log(tenantConfig)
   }

   const handleUpdate = () => 
   {
    
        callApiWithToken({
          endpoint: `/api/tenant/${tenant?.id}/tenantsconfiguration`,
          method: 'PUT', // Your request payload
          body: tenantConfig
        }).then(data => {

          onUpdate(data)
          
        }).catch(error => {
          console.error(error);
        });
   }

  if(tenantConfigIsLoading)
  {
    return  <Loader ringCollor='lightgrey' viewbox='-75 10 200 50' text='Loading tenant deploy configuration'></Loader>
  }

  return    (
    <div style={style}>


<Container isVertical>
<Container>
<Text textStyle='h6'>General Settings</Text>
<Button2
        buttonRadius="8px"
        buttonSize="40px"
        text={"Update Tenant"}
        disabled={checkUpdate(tenant?.deployStatus) }
        onClick={() => handleUpdate()}
        variant="contained"
        />
</Container>


<Container isVertical>
<Container>

    <TextFieldWrapper labelText={"Suite Hostname"}>
            <TextField
                value={tenantConfig?.feHostname}
                onChange={(e) => handleTenantConfigurationChange("feHostname",e.target.value)}
                type="text"
                variant="default"
            />
            </TextFieldWrapper>
            <TextFieldWrapper labelText={"Suite Runmode"}>
            <Autocomplete
            value={tenantConfig?.feRunMode}
            onChange={(e) => handleTenantConfigurationChange("feRunMode",e.value)}
            options={[
              {
                label: 'Full',
                value: 'Full'
              },
              {
                label: 'NoCustomers',
                value: 'NoCustomers'
              },
              {
                label: 'CustomersOnly',
                value: 'CustomersOnly'
              }
            ]}
              
            />
            </TextFieldWrapper>

 
</Container>

</Container>
<Divider></Divider>
<Container>
        <Text textStyle='h6'>Authentication Settings</Text>
        </Container>

<Container isVertical>
<Container>

    <TextFieldWrapper labelText={"Client ID"}>
            <TextField

                value={tenantConfig?.clientId}
                isDisabled={tenantConfig?.authenticationType != "AzureAd" }
                onChange={(e) => handleTenantConfigurationChange("clientId",e.target.value)}
                type="text"
                variant="default"
            />
            </TextFieldWrapper>
            <TextFieldWrapper labelText={"Client Secret"}>
            <TextField
                isDisabled={tenantConfig?.authenticationType != "AzureAd" }
                value={tenantConfig?.clientSecret}
                onChange={(e) => handleTenantConfigurationChange("clientSecret",e.target.value)}

                type="text"
                variant="default"
            />
            </TextFieldWrapper>

 
</Container>
<Container>
<TextFieldWrapper labelText={"Tenant Id"}>
            <TextField

                value={tenantConfig?.idpAzureAdTenantId}
                isDisabled={tenantConfig?.authenticationType != "AzureAd" }
                onChange={(e) => handleTenantConfigurationChange("idpAzureAdTenantId",e.target.value)}

                type="text"
                variant="default"
            />
            </TextFieldWrapper>
            <TextFieldWrapper labelText={"Authentication Type"}>
            <Autocomplete
            value={tenantConfig?.authenticationType}
            onChange={(e) => handleTenantConfigurationChange("authenticationType",e.value)}
            options={[
              {
                label: 'AzureAd',
                value: 'AzureAd'
              },
              {
                label: 'Local',
                value: 'local'
              }
            ]}
              
            />
            </TextFieldWrapper>
</Container>

</Container>
<Divider></Divider>
<Container>
<Text textStyle='h6'>Customer Settings</Text>
</Container>
<Container isVertical>
<Container>
<TextFieldWrapper labelText={"Portal Hostname"}>
            <TextField

                value={tenantConfig?.customersUIHostname}
                onChange={(e) => handleTenantConfigurationChange("customersUIHostname",e.target.value)}
                isDisabled={tenantConfig?.feRunMode == "NoCustomers" }
                type="text"
                variant="default"
            />
            </TextFieldWrapper>
            <TextFieldWrapper labelText={"R version"}>
            <TextField
              isDisabled={tenantConfig?.feRunMode == "NoCustomers" }
              value={tenantConfig?.suiteVersion}
              onChange={(e) => handleTenantConfigurationChange("suiteVersion",e.target.value)}

                type="text"
                variant="default"
            />
            </TextFieldWrapper>
</Container>
<Container>
<TextFieldWrapper labelText={"Application ID"}>
            <TextField
            isDisabled={tenantConfig?.feRunMode == "NoCustomers" }
                
            value={tenantConfig?.gateway1CustomerAplicationId}
            onChange={(e) => handleTenantConfigurationChange("gateway1CustomerAplicationId",e.target.value)}

            type="text"
            variant="default"
        />

 
            </TextFieldWrapper>
            <TextFieldWrapper labelText={"Backend Url"}>
            <TextField
                isDisabled={tenantConfig?.feRunMode == "NoCustomers" }
                value={tenantConfig?.gateway1CustomerHostname}
                onChange={(e) => handleTenantConfigurationChange("gateway1CustomerHostname",e.target.value)}

                type="text"
                variant="default"
            />
            </TextFieldWrapper>
</Container>

<Container>
<TextFieldWrapper labelText={"Ui hostname"}>
            <TextField
            isDisabled={tenantConfig?.feRunMode == "NoCustomers" }
                
            value={tenantConfig?.customersUIHostname}
            onChange={(e) => handleTenantConfigurationChange("customersUIHostname",e.target.value)}

            type="text"
            variant="default"
        />

 
            </TextFieldWrapper>
            <TextFieldWrapper labelText={"Port"}>
            <Autocomplete
            value={tenantConfig?.customersBackendPortForward}
            isDisabled={tenantConfig?.feRunMode == "NoCustomers" }
            onChange={(e) => handleTenantConfigurationChange("feRunMode",e.value)}
            options={[
              {
                label: '443',
                value: '443'
              },
              {
                label: '444',
                value: '444'
              },
              {
                label: '445',
                value: '445'
              },
              {
                label: '446',
                value: '446'
              }
            ]}
              
            />
            </TextFieldWrapper>
</Container>
</Container>
<Divider></Divider>
<Container>
<Text textStyle='h6'>Feature Flags (Until App Config)</Text>
</Container>

<Container>
<Container align='left' isVertical>
<Switch isDisabled={tenantConfig?.feRunMode == "NoCustomers" } name="ts1" label="customers mail" value='a' onChange={(e) => handleTenantConfigurationChange("customersMailEnabled",!tenantConfig?.customersMailEnabled)} isChecked={tenantConfig?.customersMailEnabled}  />
<Switch isDisabled={tenantConfig?.feRunMode == "NoCustomers" } name="ts1" label="customers chat" value='a' onChange={(e) => handleTenantConfigurationChange("customersChatEnabled",!tenantConfig?.customersChatEnabled)} isChecked={tenantConfig?.customersChatEnabled} />
<Switch isDisabled={tenantConfig?.feRunMode == "NoCustomers" } name="ts1" label="customers telephony" value='a' onChange={(e) => handleTenantConfigurationChange("customersTelephonyEnabled",!tenantConfig?.customersTelephonyEnabled)} isChecked={tenantConfig?.customersTelephonyEnabled} />
<Switch isDisabled={tenantConfig?.feRunMode == "NoCustomers" } name="ts1" label="customers tasks" value='a' onChange={(e) => handleTenantConfigurationChange("customersTasksEnabled",!tenantConfig?.customersTasksEnabled)} isChecked={tenantConfig?.customersTasksEnabled} />
<Switch isDisabled={tenantConfig?.feRunMode == "NoCustomers" } name="ts1" label="customers caseflow" value='a' onChange={(e) => handleTenantConfigurationChange("customersCaseflowEnabled",!tenantConfig?.customersCaseflowEnabled)} isChecked={tenantConfig?.customersCaseflowEnabled} />
<Switch  name="ts1" label="disable conversations" value='a' isChecked={tenantConfig?.disableConversations} onChange={(e) => handleTenantConfigurationChange("disableConversations",!tenantConfig?.disableConversations)} />
</Container>
<Container align='left' isVertical>
<Switch isDisabled={tenantConfig?.feRunMode == "NoCustomers" } name="ts1" label="customers 360 tab" value='a' onChange={(e) => handleTenantConfigurationChange("customers360TabLeftColumn",!tenantConfig?.customers360TabLeftColumn)}  isChecked={tenantConfig?.customers360TabLeftColumn} />
<Switch isDisabled={tenantConfig?.feRunMode == "NoCustomers" } name="ts1" label="customers telephony health" value='a' onChange={(e) => handleTenantConfigurationChange("customersPhoneHealthCheckEnabled",!tenantConfig?.customersPhoneHealthCheckEnabled)} isChecked={tenantConfig?.customersPhoneHealthCheckEnabled} />
<Switch isDisabled={tenantConfig?.feRunMode == "NoCustomers" } name="ts1" label="customers disable chat notificatons" onChange={(e) => handleTenantConfigurationChange("customersDisableChatNotifications",!tenantConfig?.customersDisableChatNotifications)} value='a' isChecked={tenantConfig?.customersDisableChatNotifications} />
<Switch name="ts1" label="query config from graph" onChange={(e) => handleTenantConfigurationChange("queryConfigFromGraph",!tenantConfig?.queryConfigFromGraph)} value='a' isChecked={tenantConfig?.queryConfigFromGraph} />
<Switch name="ts1" label="content provider 2" onChange={(e) => handleTenantConfigurationChange("enableContentProvider2",!tenantConfig?.enableContentProvider2)} value='a' isChecked={tenantConfig?.enableContentProvider2} />

</Container>


</Container>




</Container>



    </div>


  );
};

export default TernantDeploymentSettings;