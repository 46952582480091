import axios from 'axios';
import { getAccessToken } from "../Auth/AuthService";

interface ApiCallOptions {
  endpoint: string;
  method: string;
  body?: any; // This can be an object if you're sending JSON, or undefined for GET requests
  additionalHeaders?: Record<string, string>; 
 // Specify that headers can have any string keys
}

async function callApiWithToken({ endpoint, method, body, additionalHeaders = {}, }: ApiCallOptions) {
  try {
    const accessToken = await getAccessToken(); // Assume this function is defined to get the access token

    // Prepare headers for Axios request
    // Now explicitly declared to be of type Record<string, string>
    const headers: Record<string, string> = {
      ...additionalHeaders,
      'Authorization': `Bearer ${accessToken}`,
    };

    // Conditionally add 'Content-Type' header for requests that include a body (except for GET or DELETE)
    if (body && method.toUpperCase() !== 'GET' && method.toUpperCase() !== 'DELETE') {
      headers['Content-Type'] = 'application/json';
    }

    // Prepare the Axios request config
    const config = {
      method: method,
      url: `https://tenants-nonproduction.embracecloud.io${endpoint}`,
      headers: headers,
      data: body, // Axios uses `data` for the request body
    };

    // Axios will automatically convert request data to JSON
    // and parse response data as JSON, so no need to explicitly handle JSON.stringify or JSON.parse
    const response = await axios(config);

    return response.data; // Return the data from the Axios response
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("API call with token failed:", error.message);
      throw error; // Re-throw the error for further handling if necessary
    } else {
      console.error("Unexpected error:", error);
      throw error; // Re-throw unexpected errors
    }
  }
}

export default callApiWithToken;