import React, {useEffect, useState} from 'react';
import PageLayout from '../Components/PageLayout/PageLayout';
import TenantList from '../Components/Tenants/TenantList';
import callApiWithToken from '../Utilities/ApiService/ApiService';
import TenantDetails from '../Components/Tenants/TenantDetails';
import { SubscriptionWithTenants, Tenant } from '../Interfaces/Types';
import TenantExtendedDetails from '../Components/Tenants/TenantExtendedDetails';




const HomePage: React.FC = () => {
    const [tenants, setTenants] = useState<SubscriptionWithTenants[]>([]);
    const [isTenantsLoading, setIsTenantsLoading] = useState(true);

    const [selectedTenant,setSelectedTenant] = useState<Tenant>();
    const [tenantUpdated, setTenantUpdated] = useState<Date>();

    useEffect(() => {

        callApiWithToken({
            endpoint: '/api/subscription',
            method: 'GET', // Your request payload
          }).then(data => {
            setTenants(data);
            setIsTenantsLoading(false);
          }).catch(error => {
            console.error(error);
          });
        
      }, [tenantUpdated]);

    const handleTenantSelect = (tenant: Tenant) =>
    {
 
        setSelectedTenant(tenant);
     
    }



    


  return    <> <PageLayout
  section1Content={<TenantList onTenantUpdated={() => {setTenantUpdated(new Date()); setIsTenantsLoading(true)}} onUpdated={(data) => {tenants.push({subscription: data, tenants : []})}} onSelected={(id) => handleTenantSelect(id)} isLoading={isTenantsLoading} propData={tenants}></TenantList>}
  section2Content={
<TenantDetails onUpdate={() => {setTenantUpdated(new Date()); setIsTenantsLoading(true)}} tenant={selectedTenant}></TenantDetails>

}
  section3Content={<div><TenantExtendedDetails tenant={selectedTenant}></TenantExtendedDetails></div>}
/> </>
};

export default HomePage;