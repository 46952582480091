import Container from '@embracesbs/component-container/Container';
import Sidebar2 from '@embracesbs/component-sidebar2/Sidebar2';
import SidebarItem from '@embracesbs/component-sidebaritem/SidebarItem';
import React from 'react';

const menuItems = [
    {
      id: "dashboard",
      iconName: "AppSocial",
      activeIconName: "AppSocial",
      title: "",
    }
  ];
  

const MainMenu: React.FC = () => {
  return (   <> 
    <Sidebar2>
       <Container isVertical  isFullHeight>
       <section style={{
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center', 
  height: '100%', 

}}>
          {menuItems.map((item) => {
          return (
            <SidebarItem
              key={item.id}
              id={item.id}
              title={item.title}
              icon={{
                iconName: "User"
              }
              }
            />
          );
        })}
        
        </section>
       </Container>
   </Sidebar2> 
  
   </>)
};

export default MainMenu;