import React, { useState } from 'react';
import Text from "@embracesbs/component-text";
import { Tenant } from '../../Interfaces/Types';
import Divider from '@embracesbs/component-divider/Divider';
import SupportLogin from './SupportLogin';




interface tenantExtendedDetailsProbs {
    tenant?: Tenant
}


const TenantExtendedDetails: React.FC<tenantExtendedDetailsProbs> = ({tenant}) => {
    

   if(!tenant){
    return(
        <>
         
        </>
    )
   }   
      

  return    (
    <>

        <Text textStyle='h6'>Deploytime status</Text>

        <br></br>
        <Text textColor='grey' textStyle='description'>IsLocked:</Text>
        <Text textStyle='body' >{tenant.activeReleaseLock.toString()}</Text>
        <br></br>
        <Text textColor='grey' textStyle='description'>status:</Text>
        <Text textStyle='body' >{tenant.deployStatus}</Text>
        <br></br>
        <Divider  />
        <br></br>
        <Text textStyle='h6'>Tenant Properties</Text>
        <br></br>
        <Text textColor='grey' textStyle='description'>Name:</Text>
        <Text textStyle='body' >{tenant.tenantName}</Text>
        <br></br>
        <Text textColor='grey' textStyle='description'>Display name:</Text>
        <Text textStyle='body' >{tenant.tenantDisplayname}</Text>
        <br></br>
        <Text textColor='grey' textStyle='description'>Enviroment:</Text>
        <Text textStyle='body' >{tenant.environment}</Text>
        <br></br>
        <Text textColor='grey' textStyle='description'>CreatedBy:</Text>
        <Text textStyle='body' >{tenant.createdBy}</Text>
        <br></br>
        <Text textColor='grey' textStyle='description'>CreationTime:</Text>
        <Text textStyle='body' >{tenant.creationTime}</Text>
        <br></br>
        <Text textColor='grey' textStyle='description'>LastModifiedBy:</Text>
        <Text textStyle='body' >{tenant.modifiedBy}</Text>
        <br></br>
        <Text textColor='grey' textStyle='description'>PipelineUrl:</Text>
        <Text textStyle='body' ><a href={`https://dev.azure.com/embrace/Embrace%20Suite/_build?definitionId=${tenant.azureDevopsBuildDefinitionId}`}>Azure Devops Link</a></Text>
        <br></br>
        <Text textColor='grey' textStyle='description'>InternalId:</Text>
        <Text textStyle='body' >{tenant.id}</Text>

  {tenant.deployStatus != "initializing"  ?
      <SupportLogin tenant={tenant}></SupportLogin>
      : 
      <></>
  }
  
    </>
  );
};

export default TenantExtendedDetails;